import { InfoIcon } from 'components/Icons';
import Checkbox from 'ui/components/Checkbox/Checkbox';
import { Action, HarvestKeywordsAction } from 'shared/legacy-types';
import { ADD_NEGATIVE_KEYWORDS } from 'ui/constants/actions';

type Props = {
  setSelection: (selection: Action) => void;
  selection: Action;
};

export default function AddNegativeKeywords({
  setSelection,
  selection,
}: Props): JSX.Element {
  const keywordsSelection = selection as HarvestKeywordsAction;

  function selectSendEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    setSelection({
      type: ADD_NEGATIVE_KEYWORDS,
      properties: {
        sendEmail: event.target.checked,
      },
    });
  }

  return (
    <>
      <div className="mt-2 text-sm">
        <div className="border-b border-neutral-40">
          <div className="text-neutral-80 pb-6 leading-[16.8px]  max-w-[30.188rem]">
            <span>
              Create negative keywords in your source campaigns when your
              conditions are met.
            </span>
          </div>
        </div>
      </div>
      <div className="bg-primary-40 border-primary-80 flex h-fit w-fit mt-6 rounded border px-[0.688rem] py-[0.4rem]">
        <span className="mr-1">
          <InfoIcon />
        </span>
        <span className="mt-px text-sm !leading-5">
          You must approve the results of this action before any keywords get
          added to your campaigns
        </span>
      </div>
      <div className="flex absolute bottom-[6.563rem] w-[32.938rem]">
        <Checkbox
          checked={keywordsSelection.properties.sendEmail}
          select={(event) => {
            selectSendEmail(event);
          }}
          label="Add an email alert action to this workflow to get notified when new negative keywords need approval"
          labelStyles="font-normal !leading-[1.05rem] mt-[-0.188rem] ml-3"
        />
      </div>
    </>
  );
}
