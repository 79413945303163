import {
  PercentIcon,
  PlaySquareIcon,
  SendEmailIcon,
  PauseIcon,
  BudgetIcon,
  PlusSquareIcon,
  SlackIcon,
  LargeSlackIcon,
  LargeBudgetIcon,
  LargeSendEmailIcon,
  LargePercentIcon,
  LargePauseIcon,
  LargePlusSquareIcon,
  LargePlaySquareIcon,
  CircleMinusIcon,
} from 'components/Icons';
import {
  AD_GROUPS,
  CAMPAIGNS,
  SEARCH_TERMS,
  TARGETS,
  ADS,
  PRODUCT_SIGNALS,
} from './rules';
import {
  UpdateBidAction,
  UpdateBudgetAction,
  EmailAction,
  ChangeStatusAction,
  HarvestKeywordsAction,
  AddNegativeKeywordsAction,
  SlackAction,
} from 'shared/legacy-types';

export const UPDATE_BID = 'updateBid';
export const SEND_EMAIL = 'sendEmail';
export const SEND_SLACK = 'sendSlack';
export const CHANGE_STATUS = 'changeStatus';
export const UPDATE_BUDGET = 'updateBudget';
export const HARVEST_KEYWORDS = 'harvestKeywords';
export const ADD_NEGATIVE_KEYWORDS = 'addNegativeKeywords';

export const CURRENT_BID = 'currentBid';
export const CURRENT_PRICE = 'currentPrice';
export const CURRENT_BUDGET = 'currentBudget';

export const ACTION_GROUPS = [
  {
    label: 'ADVERTISING',
    actions: [
      {
        type: UPDATE_BUDGET,
        label: 'Update budget',
        enabled: 'all',
        comingSoon: false,
        icon: BudgetIcon,
        largeIcon: LargeBudgetIcon,
        availableFor: [CAMPAIGNS],
        hide: false,
      },
      {
        type: UPDATE_BID,
        label: 'Update target bid',
        enabled: 'all',
        comingSoon: false,
        largeIcon: LargePercentIcon,
        icon: PercentIcon,
        availableFor: [TARGETS],
      },
      {
        type: 'paused',
        label: 'Pause',
        enabled: 'internal',
        comingSoon: true,
        icon: PauseIcon,
        largeIcon: LargePauseIcon,
        availableFor: [TARGETS, CAMPAIGNS],
      },
      {
        type: 'enabled',
        label: 'Enable',
        enabled: 'internal',
        comingSoon: true,
        icon: PlaySquareIcon,
        largeIcon: LargePlaySquareIcon,
        availableFor: [TARGETS, CAMPAIGNS],
      },
      {
        type: HARVEST_KEYWORDS,
        label: 'Harvest keywords',
        enabled: 'all',
        icon: PlusSquareIcon,
        largeIcon: LargePlusSquareIcon,
        comingSoon: false,
        availableFor: [SEARCH_TERMS],
      },
      {
        type: ADD_NEGATIVE_KEYWORDS,
        label: 'Add negative keywords',
        enabled: 'all',
        icon: CircleMinusIcon,
        largeIcon: CircleMinusIcon,
        comingSoon: false,
        availableFor: [SEARCH_TERMS],
        hide: true,
      },
    ],
  },
  {
    label: 'MONITORING',
    actions: [
      {
        type: SEND_EMAIL,
        label: 'Send email alert',
        enabled: 'all',
        icon: SendEmailIcon,
        largeIcon: LargeSendEmailIcon,
        comingSoon: false,
        availableFor: [TARGETS, CAMPAIGNS, SEARCH_TERMS, PRODUCT_SIGNALS],
      },
      {
        type: SEND_SLACK,
        label: 'Send message to slack',
        enabled: 'internal',
        icon: SlackIcon,
        largeIcon: LargeSlackIcon,
        comingSoon: true,
        availableFor: [PRODUCT_SIGNALS],
        hide: true,
      },
    ],
  },
];

const bidDefault = {
  type: UPDATE_BID,
  properties: {
    metric: CURRENT_BID,
    value: undefined,
    buffer: 1,
  },
} as UpdateBidAction;

const budgetDefault = {
  type: UPDATE_BUDGET,
  properties: {
    metric: CURRENT_BUDGET,
    value: undefined,
    buffer: 1,
  },
} as UpdateBudgetAction;

const emailDefault = {
  type: SEND_EMAIL,
} as EmailAction;

const pauseEnableDefault = {
  type: CHANGE_STATUS,
  properties: {
    entityType: 'campaigns',
    status: 'paused',
  },
} as ChangeStatusAction;

const harvestKeywordDefault = {
  type: HARVEST_KEYWORDS,
  properties: {
    sendEmail: false,
  },
} as HarvestKeywordsAction;

const addNegativeKeywordDefault = {
  type: ADD_NEGATIVE_KEYWORDS,
  properties: {
    sendEmail: false,
  },
} as AddNegativeKeywordsAction;

const slackDefault = {
  type: SEND_SLACK,
  properties: {
    channelId: '',
    channelName: '',
  },
} as SlackAction;

export const ACTION_DEFAULTS = {
  [UPDATE_BID]: bidDefault,
  [UPDATE_BUDGET]: budgetDefault,
  [SEND_EMAIL]: emailDefault,
  [CHANGE_STATUS]: pauseEnableDefault,
  [HARVEST_KEYWORDS]: harvestKeywordDefault,
  [ADD_NEGATIVE_KEYWORDS]: addNegativeKeywordDefault,
  [SEND_SLACK]: slackDefault,
};

export const BID_OPTIONS = [
  { value: CURRENT_BID, label: 'Current bid' },
  { value: 'cpc', label: 'CPC' },
];

export const BUDGET_OPTIONS = [
  { value: CURRENT_BUDGET, label: 'Current budget' },
];

export const PRICE_OPTIONS = [{ value: CURRENT_PRICE, label: 'Current price' }];

export const CONDITION_OPTIONS = [
  { value: 'increase', label: 'Increase' },
  { value: 'decrease', label: 'Decrease' },
];
export const BUFFER_OPTIONS = [
  { value: '1', label: '1 day' },
  { value: '2', label: '2 days' },
  { value: '3', label: '3 days' },
  { value: '4', label: '4 days' },
  { value: '5', label: '5 days' },
  { value: '6', label: '6 days' },
  { value: '7', label: '7 days' },
];

export const PAUSE_ENABLE_OPTIONS = [
  { label: 'Targets', value: TARGETS },
  { label: 'Campaigns', value: CAMPAIGNS },
  { label: 'Ad groups', value: AD_GROUPS },
  { label: 'Search terms', value: SEARCH_TERMS },
  { label: 'Ads', value: ADS },
];
