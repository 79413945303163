import { useContext } from 'react';
import { TrashIcon } from 'components/Icons';
import {
  SEND_EMAIL,
  HARVEST_KEYWORDS,
  ADD_NEGATIVE_KEYWORDS,
} from 'ui/constants/actions';
import { ActionContext } from '../ActionContext';
import EditActionModal from 'pages/Rule/Steps/Action/ActionModals/EditModal/EditActionModal';
import RemoveActionModal from 'pages/Rule/Steps/Action/ActionModals/RemoveActionModal';
import useRuleAction from './useRuleAction';
import ActionDescription from './ActionDescription';

export default function RuleAction() {
  const {
    removeAction,
    actionTitle,
    setEditModalOpen,
    editModalOpen,
    setRemoveActionOpen,
    actionTab,
    isPauseOrEnable,
    isKeywordHarvesting,
    bidValue,
    removeActionOpen,
  } = useRuleAction();

  const { action } = useContext(ActionContext);

  const Icon = actionTab.largeIcon;
  const disableEdit =
    action.type === SEND_EMAIL ||
    action.type === ADD_NEGATIVE_KEYWORDS ||
    isPauseOrEnable ||
    isKeywordHarvesting;

  return (
    <div
      className={`bg-secondary-20 grouph-[5.5rem] border-neutral-40 mt-2 flex justify-between rounded-lg border px-[0.688rem] py-[1.1875rem]`}
      aria-label="Action card"
    >
      <div className="flex">
        <div className="my-auto mr-2">{Icon && <Icon />}</div>
        <div className="my-auto">
          <div className={`font-bold`}>{actionTitle()}</div>
          {actionTab.type !== HARVEST_KEYWORDS && (
            <div className="flex mt-1">
              <ActionDescription
                actionTab={actionTab}
                bidValue={bidValue}
                action={action}
                isPauseOrEnable={isPauseOrEnable}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="group relative">
          <div
            className={`border-neutral-40 mr-4 border-r pr-4 text-sm font-bold hover:cursor-pointer
            ${disableEdit && 'text-neutral-80 disabled hover:cursor-not-allowed'} 
            `}
            onClick={
              disableEdit
                ? () => setEditModalOpen(false)
                : () => setEditModalOpen(true)
            }
          >
            <EditActionModal
              open={editModalOpen}
              setOpen={setEditModalOpen}
              actionTitle={actionTitle()}
            />
            Edit
          </div>
          {disableEdit && (
            <div
              className={`w-[10rem] top-[-3rem] right-[1.875rem] absolute h-content rounded bg-neutral-100 p-2 text-xs text-white group-hover:visible invisible`}
            >
              This action has no other settings
            </div>
          )}
        </div>
        <span
          className="hover:cursor-pointer"
          onClick={() => setRemoveActionOpen(true)}
          aria-label="Remove rule action"
        >
          <TrashIcon />
        </span>
        <RemoveActionModal
          open={removeActionOpen}
          setOpen={setRemoveActionOpen}
          removeAction={removeAction}
        />
      </div>
    </div>
  );
}
