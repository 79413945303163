import { ChangeEvent } from 'react';
import RunCard from './Run';
import { UseHistoryContainerGetRunsQuery } from 'codegen/graphql';
import RadioDropdown, { Option } from 'ui/components/Dropdowns/RadioDropdown';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';

const NO_FILTER_OPTION_VALUE = 'all';

type Props = {
  runConnection: UseHistoryContainerGetRunsQuery['runs'] | undefined;
  ruleOptions: Option[];
  ruleFilter: string;
  selectRule: (event: ChangeEvent<HTMLInputElement>) => void;
  deSelectRule: () => void;
  brandFilter: string;
  setBrandFilter: (value: string) => void;
  brandOptions: Option[];
  rulesLoading: boolean;
  brandsLoading: boolean;
  runsLoading: boolean;
  rulesError: string | undefined;
  brandsError: string | undefined;
  fetchMoreRuns: () => Promise<void>;
  hasNextPage: boolean;
};

export default function RuleHistory({
  runConnection,
  ruleOptions,
  ruleFilter,
  selectRule,
  deSelectRule,
  brandFilter,
  setBrandFilter,
  brandOptions,
  rulesLoading,
  brandsLoading,
  rulesError,
  brandsError,
  runsLoading,
  fetchMoreRuns,
  hasNextPage,
}: Props): JSX.Element {
  const runs = runConnection?.edges.map((edge) => edge.node) ?? [];
  const hasRuns = runs.length > 0;

  const filterHasValue =
    (ruleFilter.length > 0 && ruleFilter !== NO_FILTER_OPTION_VALUE) ||
    (brandFilter.length > 0 && brandFilter !== NO_FILTER_OPTION_VALUE);

  function renderRuns(): JSX.Element {
    if (runsLoading) {
      return (
        <div className="h-screen flex">
          <div className="m-auto mt-20">
            <LargeSpinner />
          </div>
        </div>
      );
    }
    if (!hasRuns) {
      return (
        <div className="rounded py-5 text-center text-sm text-neutral-80">
          <span className="mr-1">No results were found.</span>
          {filterHasValue ? (
            <>Try resetting the filters above.</>
          ) : (
            <>Enable some rules and wait for tomorrow to see your first runs.</>
          )}
        </div>
      );
    }

    const runsList = runs.map((run) => {
      return <RunCard run={run} key={run.id} />;
    });

    return <>{runsList}</>;
  }

  // this renders the filter dropdowns and the clear filters button
  return (
    <div>
      <div className="mb-6 flex">
        {rulesError == null && (
          <RadioDropdown
            label="Rule"
            options={ruleOptions}
            selectedValue={ruleFilter}
            onChange={(event) => {
              selectRule(event);
            }}
            deSelect={() => {
              deSelectRule();
            }}
            width="w-[8.75rem]"
            loading={rulesLoading}
            disabled={brandFilter != NO_FILTER_OPTION_VALUE}
          />
        )}
        {brandsError == null && (
          <div className="ml-4">
            <RadioDropdown
              label="Brand"
              options={brandOptions}
              selectedValue={brandFilter}
              onChange={(event) => {
                setBrandFilter(event.target.value);
              }}
              width="w-[8.75rem]"
              loading={brandsLoading}
              disabled={ruleFilter != NO_FILTER_OPTION_VALUE}
            />
          </div>
        )}
        {filterHasValue && (
          <div className="ml-4 my-auto">
            <SecondaryButton
              onClick={() => {
                if (brandFilter != NO_FILTER_OPTION_VALUE) {
                  setBrandFilter(NO_FILTER_OPTION_VALUE);
                }

                if (ruleFilter != NO_FILTER_OPTION_VALUE) {
                  deSelectRule();
                }
              }}
            >
              <>Clear filters</>
            </SecondaryButton>
          </div>
        )}
      </div>
      <div className="pt-3 pb-[0.6875rem] pl-4 pr-6 flex bg-secondary-40 border-b border-secondary-60 rounded mb-3">
        <div className="text-sm font-bold w-[10.25rem] flex">Last run</div>
        <div className="text-sm font-bold">Rule name</div>
      </div>
      <InfiniteScroll
        dataLength={runs.length}
        next={fetchMoreRuns}
        hasMore={hasNextPage}
        style={{ overflow: 'hidden' }}
        loader={
          <div className="text-center mt-4 text-sm">Loading more runs...</div>
        }
        endMessage={
          hasRuns && (
            <div className="text-center mt-4 text-sm">No more runs.</div>
          )
        }
      >
        {renderRuns()}
      </InfiniteScroll>
    </div>
  );
}
